import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QaroniDialogConfig } from '@qaroni-core/types/dialogs/qaroni-dialog-config';
import { ErrorsHttpDialogComponent } from '@qaroni-shared/components/dialogs/errors-http-dialog/errors-http-dialog.component';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ManageErrorsInterceptor implements HttpInterceptor {
  private disabledRegExpRoutes: RegExp[] = [
    /\/forgots/i,
    /\/logins/i,
    /\/(clients|merchants)\/registers/i,
    /\/users\/\d+\/validates/i,
    /\/users\/\d+\/passwords/i,
    /\/merchants\/actives/i,
  ];

  private dialog = inject(MatDialog);

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    let errorInfo: HttpErrorResponse = null;

    return next.handle(req).pipe(
      tap(
        () => {
          // TODO:
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            errorInfo = error;
          }
        }
      ),
      finalize(() => {
        if (errorInfo !== null) {
          console.error('INTERCEPTOR', errorInfo);

          if (
            !this.errorIsTokenInvalid(errorInfo.status) &&
            !this.urlBelongsToDisabledRegExpRoutes(req.url)
          ) {
            const matDialogConfig = new QaroniDialogConfig<HttpErrorResponse>();
            matDialogConfig.data = errorInfo;

            this.dialog.open(ErrorsHttpDialogComponent, matDialogConfig);
          }
        }
      })
    );
  }

  private errorIsTokenInvalid(status: number): boolean {
    if (status === 401 || status === 403) {
      return true;
    } else {
      return false;
    }
  }

  private urlBelongsToDisabledRegExpRoutes(url: string): boolean {
    let belong = false;

    for (const regExpRoute of this.disabledRegExpRoutes) {
      if (regExpRoute.test(url)) {
        belong = true;
        break;
      }
    }

    return belong;
  }
}
