import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { finalize, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
  private progressBar = inject(ProgressBarService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.progressBar.setLoading(true, request?.url);

    return next
      .handle(request)
      .pipe(finalize(() => this.progressBar.setLoading(false, request?.url)));
  }
}
