import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/application/auth/services/o-auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthInterceptor implements HttpInterceptor {
  private oAuthStorage = inject(OAuthStorageService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    let authReq = req;

    if (this.oAuthStorage.hasOAuth) {
      const headers = req.headers.set(
        'Authorization',
        'Bearer ' + this.oAuthStorage.get().access_token
      );
      authReq = req.clone({ headers });
    }
    return next.handle(authReq);
  }
}
